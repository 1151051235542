import { RolesEnum, ModulesEnum, UserSidebarMenuTypeEnum } from "../enums";

const {
  SUPER_ADMIN,
  ORG_ADMIN,
  WAREHOUSE_MANAGER,
  SALES_MANAGER,
  ACCOUNTS_MANAGER,
  ENGAGEMENT_MANAGER,
  PROCUREMENT_MANAGER,
  JUNIOR_AGRONOMIST,
  SENIOR_AGRONOMIST,
} = RolesEnum;

const {
  DASHBOARD,
  ORGANISATIONS,
  COMMODITIES,
  HARVEST,
  ATTRIBUTES,
  CONSIGNMENTS,
  TRANSPORTS,
  FARMERS,
  EXECUTIVES,
  MANAGERS,
  PURCHASES,
  INVENTORY,
  SALES,
  ACCOUNTS,
  USERS,
  BUYERS,
  BUYERS_CUSTOMERS,
  BUYERS_REQUIREMENTS,
  // BUYERS_INTERESTS,
  PROFILE,
  CHART_OF_ACCOUNT,
  CROPS,
  DEFINE_PRODUCT,
  PROCESSOR_INVENTORY,
  PROCESSOR,
  INSURANCE,
  BULK_PAYMENTS,
  CROP_NUTRITION,
  COMPLAINT_TICKET,
  PACKAGE_OF_PRACTICE,
  TASKS,
  ACCOUNT_DELETE_REQUESTS,
} = ModulesEnum;

const { CLIENT, ADMIN } = UserSidebarMenuTypeEnum;

const UserSidebarMenu = {
  [SUPER_ADMIN]: {
    name: SUPER_ADMIN,
    admin: [
      DASHBOARD,
      ORGANISATIONS,
      COMMODITIES,
      ATTRIBUTES,
      CROP_NUTRITION,
      USERS,
      BUYERS,
      PROFILE,
    ],
    client: [
      DASHBOARD,
      COMMODITIES,
      CROPS,
      HARVEST,
      FARMERS,
      EXECUTIVES,
      PURCHASES,
      MANAGERS,
      ACCOUNT_DELETE_REQUESTS,
      INVENTORY,
      SALES,
      ACCOUNTS,
      PROCESSOR,
      USERS,
      PROFILE,
      PACKAGE_OF_PRACTICE,
      INSURANCE,
      COMPLAINT_TICKET,
      TASKS,
    ],
    default: ADMIN,
  },
  [ORG_ADMIN]: {
    name: ORG_ADMIN,
    admin: [DASHBOARD, ORGANISATIONS, COMMODITIES, USERS, BUYERS, PROFILE],
    client: [
      DASHBOARD,
      COMMODITIES,
      CROPS,
      HARVEST,
      FARMERS,
      EXECUTIVES,
      PURCHASES,
      MANAGERS,
      ACCOUNT_DELETE_REQUESTS,
      INVENTORY,
      SALES,
      ACCOUNTS,
      PROCESSOR,
      USERS,
      PROFILE,
      PACKAGE_OF_PRACTICE,
      INSURANCE,
      COMPLAINT_TICKET,
      TASKS,
    ],
    default: CLIENT,
  },
  [WAREHOUSE_MANAGER]: {
    name: WAREHOUSE_MANAGER,
    client: [
      DASHBOARD,
      PURCHASES,
      INVENTORY,
      PROFILE,
      CONSIGNMENTS,
      TRANSPORTS,
    ],
    admin: [PROFILE],
    default: CLIENT,
  },
  [SALES_MANAGER]: {
    name: SALES_MANAGER,
    client: [
      DASHBOARD,
      HARVEST,
      PURCHASES,
      SALES,
      INVENTORY,
      PROCESSOR,
      PROFILE,
    ],
    admin: [PROFILE],
    default: CLIENT,
  },
  [ACCOUNTS_MANAGER]: {
    name: ACCOUNTS_MANAGER,
    client: [DASHBOARD, PURCHASES, SALES, ACCOUNTS, PROFILE],
    admin: [PROFILE],
    default: CLIENT,
  },
  [ENGAGEMENT_MANAGER]: {
    name: ENGAGEMENT_MANAGER,
    client: [
      DASHBOARD,
      HARVEST,
      FARMERS,
      EXECUTIVES,
      MANAGERS,
      PROCESSOR,
      PROFILE,
      BULK_PAYMENTS,
    ],
    admin: [PROFILE],
    default: CLIENT,
  },
  [PROCUREMENT_MANAGER]: {
    name: PROCUREMENT_MANAGER,
    client: [
      DASHBOARD,
      COMMODITIES,
      HARVEST,
      EXECUTIVES,
      FARMERS,
      PURCHASES,
      INVENTORY,
      PROFILE,
      CONSIGNMENTS,
    ],
    admin: [PROFILE],
    default: CLIENT,
  },
  [SENIOR_AGRONOMIST]: {
    name: SENIOR_AGRONOMIST,
    client: [
      DASHBOARD,
      HARVEST,
      FARMERS,
      COMPLAINT_TICKET,
      PACKAGE_OF_PRACTICE,
      PROFILE,
      TASKS,
    ],
    admin: [],
    default: CLIENT,
  },
  [JUNIOR_AGRONOMIST]: {
    name: JUNIOR_AGRONOMIST,
    client: [
      DASHBOARD,
      HARVEST,
      FARMERS,
      COMPLAINT_TICKET,
      PACKAGE_OF_PRACTICE,
      PROFILE,
      TASKS,
    ],
    admin: [],
    default: CLIENT,
  },
};

export default UserSidebarMenu;
