
import { h, Component, watch, ref, onMounted } from "vue";
import { RolesEnum, ModulesEnum } from "@/config/enums";
import { storeToRefs } from "pinia";
import UserSidebarMenu from "@/config/permissions/userSidebarConfig";
import { NMenu, NIcon, MenuOption, NLayoutSider } from "naive-ui";
import { RouterLink } from "vue-router";
import DashboardIcon from "@/assets/icons/DashboardIcon.vue";
import DomainIcon from "@/assets/icons/DomainIcon.vue";
import CommodityIcon from "@/assets/icons/CommodityIcon.vue";
import FarmerIcon from "@/assets/icons/FarmerIcon.vue";
import ExecutiveIcon from "@/assets/icons/ExecutiveIcon.vue";
import ManagerIcon from "@/assets/icons/ManagerIcon.vue";
import PurchaseIcon from "@/assets/icons/PurchaseIcon.vue";
import InventoryIcon from "@/assets/icons/InventoryIcon.vue";
import SalesIcon from "@/assets/icons/SaleIcon.vue";
import AccountIcon from "@/assets/icons/AccountIcon.vue";
import UserIcon from "@/assets/icons/UserIcon.vue";
import BuyerIcon from "@/assets/icons/BuyerIcon.vue";
import BuyerCustomerIcon from "@/assets/icons/BuyerCustomerIcon.vue";
import BuyerRequirementIcon from "@/assets/icons/BuyerRequirementIcon.vue";
import ProfileIcon from "@/assets/icons/ProfileIcon.vue";
import CropIcon from "@/assets/icons/CropIcon.vue";
import LocationIcon from "@/assets/icons/LocationIcon.vue";
import FactoryIcon from "@/assets/icons/FactoryIcon.vue";
import CropsListIcon from "@/assets/icons/CropsListIcon.vue";
import InsuranceIcon from "@/assets/icons/InsuranceIcon.vue";
import TransportIcon from "@/assets/icons/TransportIcon.vue";
import FertilizerIcon from "@/assets/icons/FertilizerIcon.vue";
import TicketIcon from "@/assets/icons/TIcketIcon.vue";
import POPIcon from "@/assets/icons/POPIcon.vue";
import TaskIcon from "@/assets/icons/TaskIcon.vue";
import AccountDeleteIcon from "@/assets/icons/AccountDeleteIcon.vue";
import { useAuthStore } from "@/stores/auth.store";
import Utils from "@/utils/util";

const {
  DASHBOARD,
  ORGANISATIONS,
  COMMODITIES,
  CROPS,
  ATTRIBUTES,
  CONSIGNMENTS,
  TRANSPORTS,
  HARVEST,
  FARMERS,
  EXECUTIVES,
  LIVETRACKING,
  MANAGERS,
  PURCHASES,
  INVENTORY,
  SALES,
  ACCOUNTS,
  USERS,
  BUYERS,
  BUYERS_CUSTOMERS,
  SALES_CUSTOMERS,
  SALES_INVOICES,
  PAYMENTS,
  DEDUCTIONS,
  STOCK_ADJUSTMENTS,
  STOCK_TRANSFERS,
  WAREHOUSES,
  PROFILE,
  CHART_OF_ACCOUNT,
  REPORTS,
  BULK_PAYMENTS,
  DEFINE_PRODUCT,
  PURCHASE_ORDERS,
  BRAND_PURCHASE_ORDERS,
  PROCESSOR_INVENTORY,
  PROCESSOR_CONSIGNMENTS,
  PROCESSOR,
  PROCESSOR_CUSTOMERS,
  PROCESSOR_SALES_INVOICES,
  PROCESSOR_REQUIREMENTS,
  BRAND_REQUIREMENTS,
  INSURANCE,
  PACKAGING,
  CROP_NUTRITION,
  PESTICIDES,
  FERTILIZERS,
  PACKAGE_OF_PRACTICE,
  COMPLAINT_TICKET,
  TASKS,
  POP_CROPS,
  GRADE_CONVERTION,
  BATCH_PL,
  ACCOUNT_DELETE_REQUESTS,
} = ModulesEnum;

const MenuRoutes: any = [
  {
    path: "/",
    name: DASHBOARD,
    icon: DashboardIcon,
  },
  {
    path: "/organisations",
    name: ORGANISATIONS,
    icon: DomainIcon,
  },
  {
    path: "/commodities",
    name: COMMODITIES,
    icon: CommodityIcon,
  },
  {
    path: "/crops",
    name: CROPS,
    icon: CropsListIcon,
  },
  {
    path: "/attributs",
    name: ATTRIBUTES,
    icon: CropIcon,
  },
  {
    path: "/harvest",
    name: HARVEST,
    icon: CropIcon,
  },
  {
    path: "/farmers",
    name: FARMERS,
    icon: FarmerIcon,
  },
  {
    path: "/executives",
    name: EXECUTIVES,
    icon: ExecutiveIcon,
    children: [
      {
        path: "/executives",
        name: EXECUTIVES,
        icon: ExecutiveIcon,
      },
      {
        path: "/executives/livetracking",
        name: LIVETRACKING,
        icon: LocationIcon,
      },
    ],
  },
  {
    path: "/managers",
    name: MANAGERS,
    icon: ManagerIcon,
  },
  {
    path: "/deleterequests/",
    name: ACCOUNT_DELETE_REQUESTS,
    icon: AccountDeleteIcon,
  },
  {
    path: "/purchases",
    name: PURCHASES,
    icon: PurchaseIcon,
  },
  {
    path: "/inventory",
    name: INVENTORY,
    icon: InventoryIcon,
    children: [
      {
        path: "/inventory/items",
        name: INVENTORY,
        icon: InventoryIcon,
      },
      {
        path: "/inventory/warehouses",
        name: WAREHOUSES,
        icon: InventoryIcon,
      },
      {
        path: "/inventory/stock-adjustments",
        name: STOCK_ADJUSTMENTS,
        icon: InventoryIcon,
      },
      {
        path: "/inventory/stock-transfers",
        name: STOCK_TRANSFERS,
        icon: InventoryIcon,
      },
      {
        path: "/inventory/grade_conversion",
        name: GRADE_CONVERTION,
        icon: InventoryIcon,
      },
    ],
  },
  {
    path: "/sales",
    name: SALES,
    icon: SalesIcon,
    children: [
      {
        path: "/sales/customers",
        name: SALES_CUSTOMERS,
        icon: SalesIcon,
      },
      {
        path: "/sales/invoices",
        name: SALES_INVOICES,
        icon: SalesIcon,
      },
      {
        path: "/sales/consignments",
        name: CONSIGNMENTS,
        icon: SalesIcon,
      },
      {
        path: "/sales/batches",
        name: BATCH_PL,
        icon: SalesIcon,
      },
      {
        path: "/sales/transports",
        name: TRANSPORTS,
        icon: TransportIcon,
      },
    ],
  },
  {
    path: "/sales/transports",
    name: TRANSPORTS,
    icon: TransportIcon,
  },
  {
    path: "/sales/consignments",
    name: CONSIGNMENTS,
    icon: SalesIcon,
  },
  {
    path: "/accounts",
    name: ACCOUNTS,
    icon: AccountIcon,
    children: [
      {
        path: "/accounts/payments",
        name: PAYMENTS,
        icon: AccountIcon,
      },
      {
        path: "/accounts/reports",
        name: BULK_PAYMENTS,
        icon: AccountIcon,
      },
      {
        path: "/accounts/deductions",
        name: DEDUCTIONS,
        icon: AccountIcon,
      },
      {
        path: "/accounts/chart",
        name: CHART_OF_ACCOUNT,
        icon: AccountIcon,
      },
      {
        path: "/accounts/reports",
        name: REPORTS,
        icon: AccountIcon,
      },
    ],
  },
  {
    path: "/accounts/reports",
    name: BULK_PAYMENTS,
    icon: AccountIcon,
  },
  {
    path: "/users",
    name: USERS,
    icon: UserIcon,
  },
  {
    path: "/buyers",
    name: BUYERS,
    icon: BuyerIcon,
    children: [
      {
        path: "/buyers/customers",
        name: BUYERS_CUSTOMERS,
        icon: BuyerCustomerIcon,
      },
      {
        path: "/processor_inventory",
        name: PROCESSOR_INVENTORY,
      },
      {
        path: "/buyers/requirements",
        name: BRAND_REQUIREMENTS,
        icon: BuyerRequirementIcon,
      },
      {
        path: "/brand-purchse-orders",
        name: BRAND_PURCHASE_ORDERS,
        icon: PurchaseIcon,
      },
      {
        path: "/processor/consignments",
        name: PROCESSOR_CONSIGNMENTS,
      },
      {
        path: "/processor/sales-invoice",
        name: PROCESSOR_SALES_INVOICES,
      },
      {
        path: "/buyers/packaging",
        name: PACKAGING,
      },
      {
        path: "/productdefination",
        name: DEFINE_PRODUCT,
        icon: FactoryIcon,
      },
    ],
  },
  {
    name: CROP_NUTRITION,
    icon: FertilizerIcon,
    children: [
      {
        path: "/fertilizers",
        name: FERTILIZERS,
        icon: FertilizerIcon,
      },
      {
        path: "/pesticides",
        name: PESTICIDES,
        icon: FertilizerIcon,
      },
    ],
  },

  {
    name: PROCESSOR,
    icon: BuyerIcon,
    children: [
      {
        path: "/buyers/customers",
        name: PROCESSOR_CUSTOMERS,
        icon: BuyerCustomerIcon,
      },
      {
        path: "/buyers/requirements",
        name: PROCESSOR_REQUIREMENTS,
        icon: BuyerRequirementIcon,
      },
      {
        path: "/purchse-orders",
        name: PURCHASE_ORDERS,
        icon: PurchaseIcon,
      },
    ],
  },

  {
    path: "/profile",
    name: PROFILE,
    icon: ProfileIcon,
  },
];

export default {
  name: "MenuList",
  components: {
    NMenu,
    NLayoutSider,
  },
  setup() {
    const authStore = useAuthStore();
    const { user, allowedModules } = storeToRefs(authStore);

    if (!user.value) return;

    let combinedMenuItems: string[] = [];
    user.value.roles.forEach((role) => {
      let userConfig = UserSidebarMenu[role];
      const subDomain = Utils.getSubDomain();
      if (subDomain.includes("admin")) {
        combinedMenuItems = [
          ...new Set([...combinedMenuItems, ...userConfig["admin"]]),
        ];
      } else {
        combinedMenuItems = [
          ...new Set([...combinedMenuItems, ...userConfig["client"]]),
        ];
      }
    });

    onMounted(() => {
      authStore.getAllowedModules(Number(user.value?.orgId));
    });

    const generateMenuItems = (routeList: any[]) => {
      const menuItems = combinedMenuItems.map((item) => {
        const route = routeList.find((route) => route.name === item);
        if (!route)
          return {
            label: "invalid",
            key: "invalid",
          };
        return {
          label: route.children
            ? route.name
            : () =>
                h(
                  RouterLink,
                  {
                    to: {
                      name: route.name,
                    },
                  },
                  { default: route.name }
                ),
          key: item,
          icon: renderIcon(route.icon),
          children: route.children?.map((child) => {
            return {
              label: () =>
                h(
                  RouterLink,
                  {
                    to: {
                      name: child.name,
                    },
                  },
                  { default: child.name }
                ),
              // icon: renderIcon(child.icon),
              key: child.name,
            };
          }),
        };
      });

      const filterMenuItems = menuItems.filter(
        (item) => item.label !== "invalid"
      );
      return filterMenuItems;
    };

    const menuOptionsRef = ref<MenuOption[]>(generateMenuItems(MenuRoutes));

    const updateMenuItems = (additionalModules: string[]) => {
      menuOptionsRef.value = [...generateMenuItems(MenuRoutes)];

      const tempModuleArr: any = [];
      if (additionalModules.length) {
        additionalModules.forEach((module) => {
          if (module === "INSURANCE") {
            const insurance = {
              path: "/insurance",
              name: INSURANCE,
              icon: InsuranceIcon,
            };

            tempModuleArr.push(insurance);
          }

          if (module === "EXTENSION_SERVICE") {
            const tickets = {
              name: COMPLAINT_TICKET,
              icon: TicketIcon,
              path: "/complaint_tickets",
            };
            tempModuleArr.push(tickets);
          }
          if (module === "TASKS") {
            const task = {
              path: "/tasks",
              name: TASKS,
              icon: TaskIcon,
            };
            tempModuleArr.push(task);
          }
          if (module === "POP") {
            const pop = {
              name: PACKAGE_OF_PRACTICE,
              icon: POPIcon,
              children: [
                {
                  path: "/package_of_practice",
                  name: PACKAGE_OF_PRACTICE,
                  icon: POPIcon,
                },
                {
                  path: "/pop_crops",
                  name: POP_CROPS,
                  icon: POPIcon,
                },
              ],
            };
            tempModuleArr.push(pop);
          }
        });
      }

      menuOptionsRef.value = generateMenuItems([
        ...MenuRoutes,
        ...tempModuleArr,
      ]);
    };

    watch(
      allowedModules,
      () => {
        updateMenuItems(allowedModules.value);
      },
      { deep: true }
    );

    const collapsed = ref(true);

    function renderIcon(icon: Component) {
      return () => h(NIcon, null, { default: () => h(icon) });
    }

    return {
      menuOptionsRef,
      collapsed,
    };
  },
};
